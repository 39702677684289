@import '../style.css';

.box-column {
  text-align: left; 
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columns */
  grid-row-gap: 100px; 
  padding-top: 30%;
}

.box {
  width: 200px;
  height: 200px; 
  overflow: hidden; 
  margin: 10px; 
  position: relative; 
  transition: width 0.3s, height 0.3s; 
  border-radius: 10px; 
  outline: 2px solid grey;
  transition: 0.3s ease-in-out;
}

.box.expanded {
  animation: pickedBox 1s forwards; 
}

.box.expanded .text-container{
  animation: fadeIn 1s forwards; 
}

.box:hover{
  transform: scale(1.1);
}
.text-container {
  position: absolute; 
  top: 50%; 
  left: 50%; 
  text-align: center;
  transform: translate(-50%, -50%); 
  width: 80%; 
  z-index: 1;
  user-select: none;
  text-shadow: 2px 2px 4px #000;
  outline: black 1px 0 10px;
  color: #fff;
  font-size: 20px;
}

.thumbnail-image {
  width: 100%;
  height: 100%; 
  object-fit: cover; 
  cursor: pointer;
}

/* when the box is clicked */
.box.expanded .thumbnail-image{
  animation: blur 1s forwards;
}

.box:not(.expanded) .thumbnail-image{
  animation: unblur 1s forwards; 
}



.full-image {
  width: 960px; 
  height: 540px; 
  border-radius: 10px;
  text-align: center;
  
}


.full-image-container{
  position: absolute;
  
  right: -155%; 
  top: 10%; 
  
  
}

.full-image-text-container {
  position: absolute; 
  padding-left: 5px;
  padding-right: 5px;
  opacity: 0;
  text-align: center;
  z-index: 1;
  top: 45%;
  text-shadow: 1px 1px 4px #000;
  outline: black 1px 0 10px;
  color: white;
  font-size: 20px;
}

.show-full-image{
  animation: showImage 1.5s forwards ease-in-out, blur 1s forwards;
  animation-delay: 0s, 1.5s;
}

.hide-full-image{
  animation: hideImage 5s ease-in-out, unblur 1s forwards;
}

.show-full-image-text{
  animation: showImage 1.5s forwards ease-in-out;
  animation-delay: 1.5s;

}

.hide-full-image-text{
  animation: hideImage 5s ease-in-out;
}

@keyframes pickedBox{
  from{
    outline: 2px solid grey;
  }
  to{
    outline: 2px solid #f00;
  }
}
@keyframes showImage{
  from {
    opacity: 0;
    transform: translateY(0px);
    
  }
  to {
    opacity: 1;
    transform: translateY(-50px);
    
  }

}

@keyframes hideImage{
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }

}

@keyframes unblur {
  from {
    filter: blur(10px);
  }
  to {
    filter: blur(0px);
  }
}

@keyframes blur {
  from {
    filter: blur(0px);
  }
  to {
    filter: blur(10px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
