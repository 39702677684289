@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700,900&display=swap');

/*give the entire page a light blue background*/


*{
    margin: 0;
    padding: 0;
    font-family: 'Roboto';
}
.header-banner{
    animation: transitionBannerIn 2s;
    position: relative;
    /*have the banner start 60px down from the top of the page.*/
    
}

.header-text{
    position: absolute;
    /*center the text on the left side with a large font*/
    opacity: 0;
    left: 20%;
    font-size: 50px;
    /*give the text a white color*/
    color: #fff;
    /*give the text a 50% opacity*/
    /*apply a transform to the text to center it vertically and horizontally*/
    /*transform: translate(-50%, 0%);*/
    
    /*give the text a drop shadow*/
    text-shadow: black 1px 0 10px;

    /*give the text a z-index of 1 so it appears above the image*/
    z-index: 1;

    /*give the text a padding of 10px on the left and right*/
    /*padding: 10px;*/

    /*animate the text slowly increasing in opacity*/
    animation: 
    transitionTextIn 2s forwards, transitionTextIdle 2s infinite ease-in-out;
    animation-delay: 2s,3s;
    -webkit-animation: transitionTextIn forwards 2s, transitionTextIdle 2s infinite ease-in-out;
    -webkit-animation-delay: 2s, 3s;
}

.subheader-text{
    color:#fff;
    text-shadow: black 1px 0 10px;
    text-align: center;
    z-index: 1;
    opacity: 0;
    position: absolute;
    left: 20%;
    font-size: 20px;
    width: 40%;
    animation: transitionSubheaderTextIn 1.5s forwards, transitionTextIdle 3s infinite ease-in-out;
    animation-delay: 4s, 6s;
    -webkit-animation: transitionSubheaderTextIn 2s forwards, transitionTextIdle 2s infinite ease-in-out;
    -webkit-animation-delay: 4s, 6s;
}

.body-image{
    /*have the image show up only when the user scrolls down far enough*/
    /*display: none;*/
    position: relative;
    animation: transitionBodyBannerIn 3s forwards ease-in-out;
}
.body-text{
    left: 15%;
    color:#fff;
    text-shadow: black 1px 0 10px;
    text-align: center;
    z-index: 1;
    position: absolute;

    font-size: 20px;
    
    animation: transitionBodyTextIn 3s forwards, transitionTextIdle 3s infinite ease-in-out;
    animation-delay: 3s, 4.5s;
    -webkit-animation: transitionBodyTextIn 3s forwards, transitionTextIdle 3s infinite ease-in-out;
    -webkit-animation-delay: 4s;
}

.fish-feeder{
    position: relative;
    width: 200px;
    height: 200px;
    left: 0%;
    z-index: 1;
    /*give a bit of whitespace above the image*/
    top: 50px;
    animation: transitionFishFeederIn 3s forwards, transitionTextIdle 3s infinite ease-in-out;
    animation-delay: 3s, 4.5s;
    -webkit-animation: transitionFishFeederIn 3s , transitionTextIdle 3s ease-in-out;
    -webkit-animation-delay: 4s;
    transition: 0.3s ease-in-out;
    /* make the image image expand slightly on hover*/
}

.combinedImage{
    position: relative;
    animation: transitionBodyBannerIn 4s forwards ease-in-out;
    display: flex;
    flex-direction: row;
}

.blog-title{
    color: #fff;
    text-shadow: black 1px 0 10px;
    font-size: 20px;
    text-align: center;
    position: absolute;
    top: 70%;
    right: 26%;
    z-index: 1;
    
    animation: transitionTextIdle 3s infinite ease-in-out;
    animation-delay: 4s, 6s;
    -webkit-animation:  transitionTextIdle 3s infinite ease-in-out;
    -webkit-animation-delay: 4s, 6s;
}

.about-me-section {
    
    position: absolute;
    z-index: 1;    
    
    margin-top: 20px;
    padding-left: 20px;
    padding-right: 20px;
    padding-top: 20px;
    border-radius: 40px;
    left: 2%;
    height: 90%;
    opacity: 0.9;
    background-color: #333;
    
}

.about-me-box {
    position: absolute;
    
    /* Add other styling as needed */
}

.about-me-content{
    color: #fff;
    text-shadow: black 1px 0 10px;
    font-size: 10px;
    text-align: center;
    position: absolute;
    z-index: 1;
    left: 55%;
    border-radius: 10px;
    border: 1px solid #fff;
    
}

.about-me-section-descriptor{
    position: relative;
    z-index: 1;
    top: 15%;
    text-align: center;
    color: #fff;
    text-shadow: black 1px 0 10px;
    font-size: 20px;
    animation: transitionTextIdle 3s infinite ease-in-out;
}

.footer{
    position: relative;
    text-align: center;
    color: white;
    z-index: 1;
}
/*
.header-banner:hover .header-text{
    
    transform: scale(1.2) translateY(-50px) ;
    
}
*/
/* body{
    transform: transitionIn 2s ease-in-out;
} */


@keyframes transitionBannerIn{
    from{
        opacity: 0.3;
        transform: translateY(60px);
        
    }
    to{
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes transitionTextIn{
    from{
        display: none;
        opacity: 0;
        /*start from the bottom of the screen and then fade up to the half of the screen*/
        top: 50%;
    }
    to{
        opacity: 1;
        top: 45%;
        
        
    }
}

@keyframes transitionSubheaderTextIn{
    from{
        opacity: 0;
        top: 60%;
        
    }
    to{
        opacity: 1;
        top: 52%;
        
        
    }
}

@keyframes transitionBodyTextIn{
    from{
        opacity: 0;
        top: 20%;
        
    }
    to{
        opacity: 1;
        top: 5%;
        
        
    }
}
@keyframes transitionTextIdle{
    /*make the text bob up and down*/
    0%{
        transform: translateY(0px);
    }
    50%{
        transform: translateY(-10px);
    }
    100%{
        transform: translateY(0px);
    }

}

@keyframes transitionBodyBannerIn{
    from{
        opacity: 0.1;
        transform: translateY(60px);
        
    }
    to{
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes transitionFishFeederIn{
    from{
        opacity: 0.1;
        transform: translateY(60px);
        
    }
    to{
        opacity: 1;
        transform: translateY(0px);
    }

}


.fish-feeder:hover{
    transform: scale(1.1);
}
.fish-feeder:active{
    transform: scale(1);
}

html {
    height: 100%;
    overflow: hidden;
    width: 100%;
}

body {
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    background-color: #333;
}

/*remove the scroll bar*/
::-webkit-scrollbar {
    display: none;
}
